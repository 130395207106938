import http from '@/services/http'

const SET_ACTIONS = 'SET_ACTIONS'
const ADD_ACTION = 'ADD_ACTION'
const UPDATE_ACTION = 'UPDATE_ACTION'
const DELETE_ACTION = 'DELETE_ACTION'
const SET_ACTION_HANDLERS = 'SET_ACTION_HANDLERS'

const state = {
  actions: [],
  actionHandlers: []
}

// getters
const getters = {
  getActions: () => state.actions,
  getActionById: (state) => (actionId) => {
    return state.actions.find((r) => actionId === r.id)
  },
  getActionHandlers: () => state.actionHandlers,
  getActionHandlerForAction: (state) => (action) => {
    const actionHandler = state.actionHandlers.find((a) => action.general_action_handler_id === a.id)
    return actionHandler
  }
}

// actions
const actions = {
  async listActions ({ commit }, { actionGroup, actionableId }) {
    const { data } = await http.get('general-actions', {
      params: {
        action_group: actionGroup,
        actionable_id: actionableId
      }
    })
    commit(SET_ACTIONS, data.data)
  },
  async createAction ({ commit }, { payload }) {
    const { data } = await http.post('general-actions', payload)
    commit(ADD_ACTION, data.data)
  },
  async updateAction ({ commit, state }, { action, payload }) {
    const { data } = await http.patch(`general-actions/${action.id}`, payload)
    const index = state.actions.findIndex((r) => r.id === action.id)
    commit(UPDATE_ACTION, { index, action: data.data })
  },
  async deleteAction ({ commit, state }, { action }) {
    await http.delete(`general-actions/${action.id}`)
    const index = state.actions.findIndex((r) => r.id === action.id)
    commit(DELETE_ACTION, index)
  },
  async listActionHandlers ({ commit }, { actionGroup }) {
    const { data } = await http.get('general-action-handlers', {
      params: {
        filter: {
          action_group: actionGroup
        }
      }
    })
    commit(SET_ACTION_HANDLERS, data.data)
  },
  async getConfigFields ({ commit }, { generalActionHandlerId, payload }) {
    const { data } = await http.post(`general-action-handlers/${generalActionHandlerId}/configure`, payload)
    return data.data
  }
}

// mutations
const mutations = {
  [SET_ACTIONS] (state, value) {
    state.actions = value
  },
  [ADD_ACTION] (state, action) {
    state.actions.push(action)
  },
  [UPDATE_ACTION] (state, { index, action }) {
    state.actions.splice(index, 1, action)
  },
  [DELETE_ACTION] (state, index) {
    state.actions.splice(index, 1)
  },
  [SET_ACTION_HANDLERS] (state, value) {
    state.actionHandlers = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
